.App {
  text-align: center;
  font-family: "almoni_neue_dl_4.0_aaaregular";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* MDana.js Style >>>>>> */
.modalContentStyle {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  border-color: transparent;
  bottom: 0;
  position: fixed !important;
  margin-bottom: 39px;
  /* height: 91%; */
  /* overflow-y: scroll; */
  border: none !important;
  width: 375px !important;
  /* overflow: scroll; */
  margin-left: calc(50% - 187px);
  margin-right: calc(50% - 187px);
}

@media only screen and (max-height: 600px) {
  .modalContentStyle {
    height: 91% !important;
    top: 0;
    bottom: auto;
  }
}

@media only screen and (max-width: 600px) {
  .modalContentStyle {
    width: 100% !important;
    margin-left: auto;
    margin-right: auto;
  }
}
.modalDialogStyle .modal-dialog {
  display: contents;
}

.divBtn {
  background-color: tomato !important;
}

.divBtn:keydown {
  width: 107px;
  height: 45px;
  border-radius: 24px;
  margin-left: 16px;
  margin-top: -19px;
  padding-top: 12px;
}
/* MDana.js Style <<< */

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ticket-bg {
  width: 40%;
}

@media only screen and (max-width: 600px) {
  .ticket-bg {
    width: 80%;
  }
}
p {
  margin-top: 0px;
  margin-bottom: 0px;
}
