@import url(https://menual.azurewebsites.net/fonts/stylesheet.css);
body {
  margin: 0;
  font-family: "almoni_neue_dl_4.0_aaaregular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a {
  touch-action: manipulation;
}

button {
  touch-action: manipulation;
}

.floatDana {
  position: fixed;
  bottom: 16px;
  right: 16px;
}

.floatFooter {
  position: fixed;
  left: 0;
  right: 0;
  width: 100% !important;
  bottom: 0px;
}
.shadow {
  cursor: crosshair;
  box-shadow: -5px -5px 5px #888;
}
code {
  font-family: "almoni_neue_dl_4.0_aaaregular", source-code-pro, Menlo, Monaco,
    Consolas, "Courier New", monospace;
}

::-webkit-input-placeholder {
  color: black;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  color: black;
  opacity: 1;
  /* Firefox */
}

::-ms-input-placeholder {
  color: black;
  opacity: 1;
  /* Firefox */
}

::placeholder {
  color: black;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: black;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: black;
}

#MButton {
  position: absolute;
  width: 288px;
  height: 59px;
  left: 44px;
  top: 557px;
  overflow: visible;
  --web-animation: fadein 0.3s ease-out;
  --web-action-type: view;
  --web-action-target: toda;
  cursor: pointer;
}

#MButton_text {
  position: absolute;
  top: 35px;
  left: 88px;
  align-self: center;
  width: 113px;
  white-space: nowrap;
  line-height: 2px;
  margin-top: -5.5px;
  font-family: "almoni_neue_dl_4.0_aaabold", Assistant;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  color: rgba(255, 255, 255, 1);
  letter-spacing: 1.16px;
}
.MHeader_bg {
  position: relative;
  top: 0;
}
.MHeader_LocationName {
  font-style: normal;
  font-weight: bold;
  font-size: 29px;
  color: rgba(152, 202, 60, 1);
  letter-spacing: 0.34px;
}
.MHeader_text {
  -webkit-filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 1));
          filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 1));
  background-color: "red";
  overflow: visible;
  white-space: nowrap;
  line-height: 26px;
  margin-top: -3.5px;
  text-align: center;
  font-family: "almoni_neue_dl_4.0_aaaregular", Assistant;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  color: rgba(255, 255, 255, 1);
  letter-spacing: 0.68px;
}
.MButton_Rectangle {
  position: absolute;
  overflow: visible;
  width: 288px;
  height: 59px;
  left: 0px;
  top: 0px;
}

.noselect {
  outline: none;
}

button:focus {
  outline: none !important;
}
span:focus {
  outline: none !important;
}
div:focus {
  outline: none !important;
}

.mbutton {
  background-color: #98ca3c;
  border: 1px solid #98ca3c;
  border-radius: 999px;
  box-sizing: border-box;
  font-family: "almoni_neue_dl_4.0_aaabold", Assistant;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  letter-spacing: 1.16px;
  color: white;
  outline: none;
}
.mbutton:hover {
  background-color: #000000;
  border: 1px solid #000000;
  color: white;
}

.mmodal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  left: 0;
  top: 0;
  width: 100vw;
  /* Full width */
  height: 100vh;
  /* Full height */
  overflow: hidden;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content/Box */
.mmodal-content {
  background-color: #fefefe;
  margin: 100px auto;
  /* 15% from the top and centered */
  border: 1px solid #aaaaaa;
  width: 100%;
  touch-action: manipulation;
  /* Could be more or less, depending on screen size */
}

/* Modal Content/Box */
.mmodal-confirm-content {
  background-color: #fefefe;
  margin: 293px auto;
  /* 15% from the top and centered */
  border: 1px solid #aaaaaa;
  width: 269px;
  touch-action: manipulation;
  /* Could be more or less, depending on screen size */
}
.mmodal-wifi-content {
  background-color: #fefefe;
  margin: 100px auto;
  /* 15% from the top and centered */
  border: 1px solid #aaaaaa;
  width: 269px;
  touch-action: manipulation;
  /* Could be more or less, depending on screen size */
}
/* The Close Button */
.close {
  float: right;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #98ca3c;
  touch-action: manipulation;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 20px solid #98ca3c;
  touch-action: manipulation;
}
.DemiBold {
  font-family: "almoni_neue_dl_4.0_aaademiBd";
}
.Bold {
  font-family: "almoni_neue_dl_4.0_aaabold";
}
.Medium {
  font-family: "almoni_neue_dl_4.0_aaaregular", Assistant;
}

html {
  scroll-behavior: smooth;
}

.App {
  text-align: center;
  font-family: "almoni_neue_dl_4.0_aaaregular";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* MDana.js Style >>>>>> */
.modalContentStyle {
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;
  border-color: transparent;
  bottom: 0;
  position: fixed !important;
  margin-bottom: 39px;
  /* height: 91%; */
  /* overflow-y: scroll; */
  border: none !important;
  width: 375px !important;
  /* overflow: scroll; */
  margin-left: calc(50% - 187px);
  margin-right: calc(50% - 187px);
}

@media only screen and (max-height: 600px) {
  .modalContentStyle {
    height: 91% !important;
    top: 0;
    bottom: auto;
  }
}

@media only screen and (max-width: 600px) {
  .modalContentStyle {
    width: 100% !important;
    margin-left: auto;
    margin-right: auto;
  }
}
.modalDialogStyle .modal-dialog {
  display: contents;
}

.divBtn {
  background-color: tomato !important;
}

.divBtn:keydown {
  width: 107px;
  height: 45px;
  border-radius: 24px;
  margin-left: 16px;
  margin-top: -19px;
  padding-top: 12px;
}
/* MDana.js Style <<< */

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ticket-bg {
  width: 40%;
}

@media only screen and (max-width: 600px) {
  .ticket-bg {
    width: 80%;
  }
}
p {
  margin-top: 0px;
  margin-bottom: 0px;
}

